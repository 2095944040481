<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog   v-model="dialog"    max-width="900px" persistent >
       <v-card>
         <v-card-title>
           <span class="text-h5">แก้ไขสินค้า</span>
           <v-spacer></v-spacer>
            <div>
           <v-btn icon  @click="dialog = false , editedItem = {}"><v-icon >mdi-close</v-icon></v-btn>
         </div>
         </v-card-title>
         <v-card-text>
           <v-container>

         <v-row class=" py-1" align="center">
          <v-col class="col-4 col-md-2 pb-2"  ><span>ราคาทุน : </span></v-col>
          <v-col class="col-12 col-md-4" >
             <v-text-field v-model="editedItem.costPrice" outlined disabled hide-details></v-text-field>
          </v-col>
         </v-row>
         <v-row class=" py-1" align="center">
          <v-col class="col-4 col-md-2 pb-2"  ><span>RackNo : </span></v-col>
          <v-col class="col-12 col-md-4"  >

          <v-text-field
             v-if=" (editedItem.locationList == undefined ? 0: this.editedItem.locationList.length)  < 2"
             v-model="editedItem.rackNo"
             outlined
             hide-details
             ref="locationRackno">
           </v-text-field>
           <v-autocomplete v-else
               v-model="editedItem.newLocation"
               :items="editedItem.locationList"
               item-text="locationName"
               item-value="locationID"
               ref="newLocation"
               outlined
               required
               hide-details
               dense>
             </v-autocomplete>

          </v-col>
         </v-row>
         <v-row class=" py-1" align="center">
          <v-col class="col-4 col-md-2 pb-2"  ><span>Lot Name : </span></v-col>
          <v-col class="col-12 col-md-4"  >
             <v-text-field
               v-model="editedItem.lotName"
               outlined
               hide-details
             ></v-text-field>
          </v-col>
         </v-row>

         <v-row class=" py-1" align="center">
          <v-col class="col-4 col-md-2 pb-2"  ><span>วันที่ผลิต : </span></v-col>
          <v-col class="col-12 col-md-4 "  >
                <v-menu
                     v-model="editedItem_menuDate.menuStartDate"
                     :close-on-content-click="false"
                     transition="scale-transition"
                     offset-y
                     min-width="290px"
                     max-width="290px"
                   >
                     <template v-slot:activator="{ on, attrs }">
                       <v-text-field
                         v-model="editedItem.manufactureDate"
                         outlined
                         readonly

                         prepend-inner-icon="mdi-calendar"
                         @blur="editedItem_menuDate.startDate = parseDate(editedItem.manufactureDate) "
                         v-bind="attrs"
                         v-on="on"
                         hide-details
                         clearable
                         @click:clear="dateStart = null"
                         style="width: 290px;"
                       ></v-text-field>
                     </template>
                     <v-date-picker v-model="editedItem_menuDate.startDate" @input="editedItem_menuDate.menuStartDate = false" @change="editedItem.manufactureDate=formatDate(editedItem_menuDate.startDate)" no-title></v-date-picker>
                </v-menu>
          </v-col>
         </v-row>
         <v-row class=" py-1" align="center">
          <v-col class="col-4 col-md-2 pb-2"  ><span>วันที่หมดอายุ : </span></v-col>
          <v-col class="col-12 col-md-4"  >
              <v-menu
                     v-model="editedItem_menuDate.menuEndtDate"
                     :close-on-content-click="false"
                     transition="scale-transition"
                     offset-y
                     min-width="290px"
                     max-width="290px"
                   >
                     <template v-slot:activator="{ on, attrs }">
                       <v-text-field
                         v-model="editedItem.expirationDate"
                         outlined
                         readonly
                         prepend-inner-icon="mdi-calendar"
                         @blur="editedItem_menuDate.endDate = parseDate(editedItem.expirationDate) "
                         v-bind="attrs"
                         v-on="on"
                         hide-details
                         clearable
                         @click:clear="dateStart = null"
                         style="width: 290px;"
                       ></v-text-field>
                     </template>
                     <v-date-picker v-model="editedItem_menuDate.endDate" @input="editedItem_menuDate.menuEndtDate = false" @change="editedItem.expirationDate=formatDate(editedItem_menuDate.endDate)" no-title></v-date-picker>
              </v-menu>
          </v-col>
         </v-row>
         <v-row  class=" py-1" >
           <v-col class="col-4 col-md-2 pb-2" > <span>รายละเอียด :</span></v-col>
            <v-col  class="col-12 col-md-6" >
             <v-textarea v-model="editedItem.remark"  solo />
           </v-col>
         </v-row>
           </v-container>
         </v-card-text>
         <v-divider/>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
               color="blue darken-1"
               text
               @click="dialog = false , editedItem = {}"
             >
               ยกเลิก
             </v-btn>
              <v-btn :elevation="1" color="primary" class="px-2" @click="saveEditChangeImportStock()">
           แก้ไข
         </v-btn>
         </v-card-actions>
       </v-card>
    </v-dialog>

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        ล็อตสินค้า
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
          </v-col>
          <v-col cols="6" align="right">
            <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel(search)">
              <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
              <span class="fn-12"> Excel </span>
              <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ร้านค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-autocomplete
              v-model="search.shop"
              placeholder="กรุณากรอกร้านค้า"
              :items="search.shopList"
              :item-text="item => item.name"
              :item-value="item => item.id "
              outlined
              required
              hide-details
              dense
             ></v-autocomplete>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> วันที่ผลิตต่ำกว่า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
                <v-menu
                    ref="search_menu_mfg_date"
                    v-model="search.menu_mfg_date"
                    :close-on-content-click="false"
                    :return-value.sync="search.mfg_date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :value="computed_search_date_mfg"
                          @change="value => computed_search_date_mfg = value"
                          autocomplete="off"
                          label="เลือกวัน"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="search.mfg_date" @input="search.menu_mfg_date = false, $refs.search_menu_mfg_date.save(search.mfg_date)" no-title  class="my-0">
                      <!-- :allowed-dates="allowedDates" -->
                    </v-date-picker>
                  </v-menu>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> LotName : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.lotName"
              @change="value => search.lotName = Trim_value(value)"
              ref="reflotName"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> วันหมดอายุต่ำกว่า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
                <v-menu
                    ref="search_menu_expire_date"
                    v-model="search.menu_expire_date"
                    :close-on-content-click="false"
                    :return-value.sync="search.expire_date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :value="computed_search_expire_date"
                          @change="value => computed_search_expire_date = value"
                          autocomplete="off"
                          label="เลือกวัน"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="search.expire_date" @input="search.menu_expire_date = false, $refs.search_menu_expire_date.save(search.expire_date)" no-title class="my-0">
                      <!-- :allowed-dates="allowedDates" -->
                    </v-date-picker>
                  </v-menu>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ชื่อสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.name"
              @change="value => search.name = Trim_value(value)"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> จำนวนคงเหลือ : </v-col>
          <v-col class="pb-1" cols="12" md="2">
             <v-select
              v-model="search.quantityCriteriaType"
              :items="search.quantityType"
              :item-text="item => item.name"
              :item-value="item => item.id "
              persistent-hint
              dense hide-details
              outlined
              single-line
            />
          </v-col>
          <v-col class="pb-1" cols="12" md="2">
            <v-text-field
              type="number"
              :value="search.quantity"
              @change="value => search.quantity = Trim_value(value)"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense hide-details
              autocomplete="off"
            />
          </v-col>

        </v-row>

        <v-row class="pb-2">
         <v-col class="pb-1" cols="12" md="2" lg="1"> SKU </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.sku"
              @change="value => search.sku = Trim_value(value)"
              @keyup.enter="load_data()"
              ref="refaddFCName"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-btn :elevation="1" class="pa-2 mt-1" color="primary" dark @click=" page_lotStock = 1, limits_lotStock = 50, offsets_lotStock = 0, load_data()">
              <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon> ค้นหา
            </v-btn>
            <vue-excel-xlsx
                id="GetExcel"
                v-show="false"
                :data="datasetExcel"
                :columns="headerExcel"
                :file-name="'ล็อตสินค้า'"
                :sheet-name="'ล็อตสินค้า'"
                >
            </vue-excel-xlsx>

          </v-col>

        </v-row>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ประเภท : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-radio-group
                v-model="search.stockType"
                row
              >
                <v-radio
                  label="ทั้งหมด"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="มีสต๊อก"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="ไม่มีสต๊อก"
                  :value="2"
                ></v-radio>
              </v-radio-group>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

     <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0 py-1"  justify="space-between" align="center">
      <v-col cols="8"  class="pa-0 ">
       <table>
          <td>
              <span class="head-doc"> สินค้า </span> ทั้งหมด {{ formatMoney(lotStock_sum) }} แถว
          </td>
       </table>
      </v-col>
      <v-col cols="2"  class="pa-0  hidden-xs-only  " align="right" >
      </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <v-data-table
      :headers="header"
      :items="lot_stock_list"
      item-key="id"
      class="elevation-0 packhai-border-table"
      :mobile-breakpoint="0"
      :items-per-page="10000000"
      hide-default-footer
      >
      <template v-slot:item.edit="{ item }">
        <v-icon  small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>

      <template v-slot:item.no="{ item }">
        {{ gen_num_data_table(lot_stock_list.indexOf(item), lotStock_sum, offsets_lotStock) }}
      </template>
      <template v-slot:item.photoLink="{ item }">
        <img v-if="item.photoLink != null" :src="item.photoLink"  @click="OpenPhoto(item.photoLink)" class="picsize mt-1">
        <img v-else src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG" class="picsize mt-1">
      </template>
      <template v-slot:item.billNumber="{ item }">
        <a @click="detail_IE(item.importExportMasterId)">{{ GenIE(item.importExportMasterId) }}</a>
      </template>
      <template v-slot:item.name="{ item }">
        <span class="color-gray-13">
           <span class="fn-13" style="font-weight: bold; text-decoration: underline"> <a @click="gotoStock_InFo(item)">{{item.name}}</a></span>
           <v-row >
            <v-col>
             <span v-if="item.productCode!=null && item.productCode!=''"> รหัสสินค้า : {{ item.productCode }}</span>
             <span v-if="item.productCode!=null && item.productCode!='' && (item.prop1Description || item.prop2Description)"> ,</span>
             <span v-if="item.prop1Description || item.prop2Description">  {{!item.prop1Description && !item.prop2Description ? "" : "ลักษณะ" + "  : "}}  {{ item.prop1Description }}  {{item.prop2Description }}</span>
            </v-col>
           </v-row>
           <v-row >
            <v-col>
              <span class="color-gray-13">{{!item.barcode ? "" : "barcode" +" : "}} {{ item.barcode }} {{item.barcode && item.sku ? ",": "" }} {{!item.sku ? ""  : "SKU" + " : "}}  {{ item.sku }}</span>
            </v-col>
           </v-row>
        </span>
      </template>
      <template v-slot:item.quantity="{ item }">
        {{ item.quantity }}
      </template>
      <template v-slot:item.quantityLeft="{ item }">
        {{ item.quantityLeft }}
      </template>

      <template v-slot:item.manufactureDate="{ item }">
        {{ set_format_date_time(item.manufactureDate) }}
      </template>

      <template v-slot:item.expirationDate="{ item }">
        {{ set_format_date_time(item.expirationDate) }}
      </template>

      <template v-slot:item.createdDatetime="{ item }">
        {{ set_format_date_time(item.createdDatetime) }}
      </template>
    </v-data-table>

    <!-- เปลี่ยนหน้า -->
    <v-row v-if="pageLength_lotStock>1 && lot_stock_list.length>0" class="text-center pa-2 ma-0" @click="changePage_lotStock()">
      <v-col cols="12" class="pa-0">

        <v-pagination
          v-model="page_lotStock"
          :length="pageLength_lotStock"
          :total-visible="7"
          color="primary"
        ></v-pagination>
      </v-col>
    </v-row>
    <div class="v-card-bottom-30" />
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import Loading from '@/website/components/Loading'
  import { stockService_dotnet, shopService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { formatMoney,  GenIE, Trim_value, formatDate} from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      // page
      page_loading: true,
      loading: false,
      progress_loading:false,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',

      // ค้นหา
      search: {
        shopList: [{id: 0, name: "--ทั้งหมด--"}],
        shop: 0,
        "lotName": null,
        "name": null,
        "productCode": null,
        "sku": null,
        "quantityCriteriaType" : 0,
        "quantityType" :[ {"id": 0, "name": "-- ยังไม่เลือก --"}, {"id": 1, "name": "เท่ากับ"}, {"id": 3, "name": "น้อยกว่าหรือเท่ากับ"}, {"id": 2, "name": "มากกว่าหรือเท่ากับ"},],
        "quantity": null,
        "stockType": 1,
        "expire_date": null,
        "mfg_date": null
      },

      // Export Excel
      loadingExcel: 0,
      datasetExcel: [],
      headerExcel: [
        { label: "No", field: "no", width: 10},
        { label: "เลขที่นำเข้า", field: "importExportMasterId", width: 50},
        { label: "LotName", field: "lotName", width: 50},
        { label: "ชื่อสินค้า", field: "name", width: 50},
        { label: "ลักษณะ1", field: "prop1", width: 40},
        { label: "คำอธิบายลักษณะ1", field: "prop1Description", width: 40},
        { label: "ลักษณะ2", field: "prop2", width: 40},
        { label: "คำอธิบายลักษณะ2", field: "prop2Description", width: 40},
        { label: "รหัสสินค้า", field: "productCode", width: 30},
        { label: "SKU", field: "sku", width: 30},
        { label: "Barcode", field: "barcode", width: 30},
        { label: "รหัส CF", field: "cfCode", width: 30},
        { label: "RackNo", field: "rackNo", width: 40},
        { label: "จำนวนรับเข้า", field: "quantity", width: 10},
        { label: "จำนวนคงเหลือ", field: "quantityLeft", width: 10},
        { label: "วันที่ผลิต", field: "manufactureDate", width: 40,cellClass: 'dateType'},
        { label: "วันหมดอายุ", field: "expirationDate", width: 40,cellClass: 'dateType'},
        { label: "วันที่สร้าง", field: "createdDatetime", width: 40},
        { label: "น้ำหนัก", field: "weight", width: 10},
        { label: "กว้าง", field: "cubic1", width: 10},
        { label: "ยาว", field: "cubic2", width: 10},
        { label: "สูง", field: "cubic3", width: 10},
        { label: "ร้านค้า", field: "shopName", width: 100},
      ],
      excelStyles: [
        {
            id: 'dateType',
            dataType: 'DateTime',
        },
      ],

      // แสดงตาราง
      lotStock_sum : 0,
      lot_stock_list: [],
      header: [
        { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', icon: 0, width: '60px' },
        { text: 'No',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'เลขบิล',align: 'center',sortable: false,value: 'billNumber', icon: 0, width: '80px'},
        { text: 'รูป',align: 'left',sortable: false,value: 'photoLink', icon: 0,width: '100px'},
        { text: 'รายละเอียดสินค้า',align: 'left',sortable: false,value: 'name', icon: 0,  },
        { text: 'LotName',align: 'left',sortable: false,value: 'lotName', icon: 0 },
        { text: 'จำนวนรับเข้า',align: 'right',sortable: false,value: 'quantity', icon: 0 ,width: '120px' },
        { text: 'จำนวนคงเหลือ',align: 'right',sortable: false,value: 'quantityLeft', icon: 0 ,width: '130px' },
        { text: 'RackNo',align: 'center',sortable: false,value: 'rackNo', icon: 0 },
        { text: 'วันที่ผลิต',align: 'center',sortable: false,value: 'manufactureDate', icon: 0 ,width: '100px'},
        { text: 'วันหมดอายุ',align: 'center',sortable: false,value: 'expirationDate', icon: 0 ,width: '110px'},
        { text: 'วันที่สร้าง',align: 'center',sortable: false,value: 'createdDatetime', icon: 0 ,width: '110px'},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0 },
      ],


      // paginations location
      isPage_lotStock: 0,
      page_lotStock: 1,
      page_tmp_lotStock: null,
      limits_lotStock: 50,
      offsets_lotStock: 0,
      pageLength_lotStock: null,


      //  edit item
      editedItem_menuDate:{menuStartDate:false,menuEndtDate:false, dateStart: null,dateEnd:null},
      editedIndex: [],
      dialog: false,
      editedItem: {},
      // บังคับกรอก
      rules: {
          locationRackno: [ val => (val || '').length > 0 ],
          quantityMove: [val => (val || '' || '0').length > 0  ],
          newLocation: [val => val  > 0 ],
      },

    }),
    computed: {
      showHeaders () {
      return this.headers.filter(s => this.headers.includes(s));
    },
      present_wait(){
        let num = this.count_print_data+ this.count_print_data_no_barcode;
        return  parseInt(num);
      },


      computed_search_date_mfg() {
        return this.formatDates(this.search.mfg_date)
      },

      computed_search_expire_date() {
        return this.formatDates(this.search.expire_date)
      },



    },
    watch: {
    },
    async created() {
      document.title = this.$router.history.current.meta.title
      this.permission = JSON.parse(localStorage.getItem('permission'))

      await this.GetShop()
      this.load_data()

      this.page_loading = false


    },
    methods: {
      async GetShop() {
        let response = await axios.post(shopService_dotnet+'Shop/get-shop-list', {
          "branchId": localStorage.getItem('Branch_BranchID'),
        }, {headers: this.header_token})
        var shopList = response.data.result
        if (response.status==200 && shopList.length>0){
          for (var i in shopList){
            this.search.shopList.push({
              id: shopList[i].shop.id,
              name: shopList[i].shop.name,
            })
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      async load_data () {
        this.loading = true

        if(this.search.quantity==''){
          this.search.quantity=null
        }
        try {
          let response = await axios.post(stockService_dotnet+'Stock/search-stockshop-item-list', {
            "dateFrom": null,
            "dateTo": null,
            "lotName": this.search.lotName,
            "branchID": localStorage.getItem('Branch_BranchID'),
            "shopID": this.search.shop==0 ? 0 : this.search.shop,
            "name": this.search.name,
            "sku": this.search.sku,
            "exp_date": !this.search.expire_date ?  null : this.search.expire_date+"T00:00:00.000Z",
            "mfg_date":!this.search.mfg_date ?  null : this.search.mfg_date+"T00:00:00.000Z",
            "quantityCriteriaType": this.search.quantityCriteriaType,
            "quantity": this.search.quantity,
            "stockType": this.search.stockType,
            "skip": this.offsets_lotStock,
            "take": this.limits_lotStock
          }, {headers: this.header_token})

          this.lot_stock_list = []
          this.lotStock_sum = 0
          if( response.data.dataList != null){
            this.lotStock_sum = response.data.resultCount
            this.lot_stock_list = JSON.parse(JSON.stringify(response.data.dataList))
          }
          this.getpagelength_lotStock(response.data.resultCount)
          this.loading = false
        }
        catch (e) {
          AlertError(e.message)
          this.loading = false
        }

      },

      detail_IE (id) {
        window.open("transfer-list/update?id="+btoa(id));
      },

      set_format_date_time (val) {
        if(val != null){
          var date_tmp = val.substring(0, 10)
          const date_split = date_tmp.split('-');
          var time_tmp = val.substring(11, 19)
          var set_dattime = date_split[2]+'-'+date_split[1]+'-'+date_split[0] +' ' //+ time_tmp
          return set_dattime
        } else {
          return ""
        }
      },
      set_format_date_time_for_excel (val) {
        if(val != null){

          var date_tmp = val.substring(0, 10)
          const date_split = date_tmp.split('-');
          var time_tmp = val.substring(11, 19)
          var set_dattime = date_split[0]+'-'+date_split[1]+'-'+date_split[2]  //+ time_tmp
          return set_dattime
        } else {
          return null
        }
      },

      getpagelength_lotStock (total_count) {
        var total = 0
        if(total_count != 0){
          total = total_count
        } else {
          total = 1
        }

        total = parseInt(total)
        this.pageLength_lotStock = Math.ceil(total/this.limits_lotStock)
        this.isPage_lotStock = 0
        this.page_tmp_lotStock = this.page_lotStock
      },

      async changePage_lotStock () {
        this.loading = true
        this.offsets_lotStock = (this.page_lotStock*this.limits_lotStock)-this.limits_lotStock

        if (this.page_lotStock > 1 && this.page_lotStock < this.pageLength_lotStock ) {
          if(this.page_tmp_lotStock != this.page_lotStock){
            await this.load_data()
            this.page_tmp_lotStock = this.page_lotStock
          }
        } else if(this.page_lotStock == 1,this.pageLength_lotStock ) {
          if(this.page_tmp_lotStock != this.page_lotStock){
            await this.load_data()
            this.page_tmp_lotStock = this.page_lotStock
          }
        }
        this.loading = false
      },

      gen_num_data_table (index, sum_data, offsets) {
        if(index != null && this.loading == false) {
          var start = null
          start = sum_data - offsets
          return formatMoney(start - index )
        } else {
          return ""
        }
      },

      async ExportExcel(search){
        this.datasetExcel = []
        this.loadingExcel = 0
        Swal.fire({
          position: "top",
          icon: 'warning',
          text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {
            var limits = 1000
            var offsets = 0
            this.loadingExcel = 1
            var total = this.lotStock_sum
            var round = parseInt( total / limits)

            if ( limits * round < total ){
              round=round+1
            }

            var current = 0
            for (var i =1; i <=round; i++) {
              var data = await this.GetExportExcelProduct(limits,offsets)
              for (var j in data){
                data[j].importExportMasterId = GenIE(data[j].importExportMasterId)
                data[j].manufactureDate = this.set_format_date_time_for_excel(data[j].manufactureDate)
                data[j].expirationDate = this.set_format_date_time_for_excel(data[j].expirationDate)
                data[j].createdDatetime = this.set_format_date_time_for_excel(data[j].createdDatetime)
                this.datasetExcel.push(data[j])
                current += 1
              }
              this.loadingExcel = parseInt(parseFloat( i) / parseFloat(round) * parseFloat(100))
              offsets += limits
            }

            for (var d in this.datasetExcel){
              this.datasetExcel[d]["no"] = this.datasetExcel.length - parseInt(d)
            }

            this.loadingExcel = this.datasetExcel.length==0 ? 0 : 100
            document.getElementById("GetExcel").click();
          }
        })
      },
      async GetExportExcelProduct(limits,offsets){
        if(this.search.quantity==''){
          this.search.quantity=null
        }
        try {
          let response = await axios.post(stockService_dotnet+'Stock/search-stockshop-item-list', {
            "dateFrom": null,
            "dateTo": null,
            "lotName": this.search.lotName,
            "branchID": localStorage.getItem('Branch_BranchID'),
            "shopID": this.search.shop==0 ? 0 : this.search.shop,
            "name": this.search.name,
            "sku": this.search.sku,
            "exp_date": !this.search.expire_date ?  null : this.search.expire_date+"T00:00:00.000Z",
            "mfg_date":!this.search.mfg_date ?  null : this.search.mfg_date+"T00:00:00.000Z",
            "quantityCriteriaType": this.search.quantityCriteriaType,
            "quantity": this.search.quantity,
            "stockType": this.search.stockType,
            "skip": offsets,
            "take": limits,
            "isAscending":true
          }, {headers: this.header_token})
          if (response.data.dataList != null){
            return response.data.dataList
          }else{
            return []
          }
        }
        catch (e) {
          AlertError(e.message)
          this.loading = false
        }
      },

      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },

      gotoStock_InFo(item){
        window.open("stock-info?id="+item.stockShopId);
      },

      async editItem (item) {
        this.editedIndex = this.lot_stock_list.indexOf(item)
        this.editedItem = Object.assign({}, item)

        if(this.editedItem.expirationDate != null){
          this.editedItem.expirationDate = formatDate(item.expirationDate.substring(0, 10))
        }

        if(this.editedItem.manufactureDate != null){
          this.editedItem.manufactureDate = formatDate(item.manufactureDate.substring(0, 10))
        }


        this.editedItem.locationList = await this.openDialog()
        if(this.editedItem.locationList == null){
          this.editedItem.locationList = []
        }


        if(this.editedItem.locationID == null && this.editedItem.locationList.length > 0){
          this.editedItem.newLocation = 0
        } else {
          this.editedItem.newLocation = this.editedItem.locationID

        }

        this.dialog = true

      },

      async openDialog(){
        let res_obj = []

        await axios.post(stockService_dotnet+'Stock/load-all-location', {
                  "branchId": localStorage.getItem('Branch_BranchID')
                }, {headers: this.header_token
           }).then( (res) => { res_obj = res.data }).catch((error) => { res_obj = null })

           return await res_obj.data

       },

       // formatDate (date) {
       //   if (!date) return null
       //   const [year, month, day] = date.split('-')
       //   return `${day}/${month}/${year}`
       // },
       parseDate (date) {
         if (!date) return null
         const [day, month, year] = date.split('/')
         return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
       },

       async saveEditChangeImportStock () {
         await Object.assign(this.lot_stock_list[this.editedIndex], this.editedItem)
         let stockShopItemId = this.lot_stock_list[this.editedIndex].stockShopItemId;
         let stockShopId = this.lot_stock_list[this.editedIndex].stockShopId;
         let bodyItemList = this.lot_stock_list[this.editedIndex];
         !bodyItemList.expirationDate ?  true :  bodyItemList.expirationDate = this.parseDate(bodyItemList.expirationDate);
         !bodyItemList.manufactureDate ?  true : bodyItemList.manufactureDate =this.parseDate(bodyItemList.manufactureDate);
         var rackNo_sting = null
         if(typeof bodyItemList.rackNo == "number"){
           rackNo_sting = null

           for (var items1 of bodyItemList.locationList) {
             if(items1.locationID == bodyItemList.rackNo){
               rackNo_sting = items1.locationName ;
               break;
             }
           }
         }else {
           rackNo_sting = bodyItemList.rackNo
         }

          await axios.post(stockService_dotnet+'Stock/update-stockshop-item-list', {
              "stockShopId": stockShopId,
              "stockShopItemId": stockShopItemId,
              "manufactureDate": bodyItemList.manufactureDate,
              "expirationDate": bodyItemList.expirationDate,
              "rackNo": rackNo_sting ,
              "lotName" : bodyItemList.lotName,
              "remark": bodyItemList.remark,
              "costPrice": bodyItemList.costPrice,
              "location_id": bodyItemList.newLocation
            }, {headers: this.header_token
            }).then( (res) => {
                this.dialog = false
                this.editedItem.locationID  = this.editedItem.newLocation
                this.editedItem = {}
                this.load_data()
            }).catch((error) => {
              Swal.fire({
              icon: 'error',
            title: 'แก้ไขข้อมูลไม่สำเร็จ',
            text: 'กรุณาลองใหม่อีกครั้ง',
            })
           });
        },



      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      formatMoney,
      Trim_value,
      AlertSuccess,
      AlertWarning,
      AlertError,
      formatDate,
      GenIE
    }
  }
</script>

<style scoped>
  >>>.v-select__slot{
    height: 40px;
  }
  .cut-text{
text-decoration: none;
text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */
display: block; overflow: hidden;
white-space: nowrap;
width: 80%; /* กำหนดความกว้าง */
}

  .sort-bar{
    font-weight: 400;
    background: rgba(0,0,0,.03);
    padding: 0.8125rem 1.25rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .w-200P{
    width: 200px ;
  }
  .p-15{
    padding: 0px 0px 0px 15px;
  }
  .w-13r{
   width: 13rem;
  }

 >>>.v-dialog {
    overflow-y: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
  }


</style>
